import { OutputValues } from "./types";

export type WishPensionWithAgeArgs = {
  currentWishPension: number;
  currentAge: number;
  age: number;
  inflation: number;
  taxRate: number;
};

export function wishPensionWithAge({
  currentAge,
  age,
  currentWishPension,
  taxRate,
  inflation,
}: WishPensionWithAgeArgs): number {
  return (
    currentWishPension * (1 + inflation) ** (age - currentAge) * (1 + taxRate)
  );
}

export type NeededCapitalArgs = {
  wertEntwicklungEntnahmephase: number;
  inflation: number;
  currentAge: number;
  endAge: number;
  startAge: number;
  startPension: number;
  statutoryPension: number;
  statutoryPensionIncrease: number;
  pensionScheme: number;
  hasRealEstate: boolean;
};

export function PV(
  monthInterest: number,
  countTimeframe: number,
  payment: number
) {
  // https://www.investopedia.com/terms/p/present-value-annuity.asp

  return (
    -payment * ((1 - 1 / (1 + monthInterest) ** countTimeframe) / monthInterest)
  );
}

export function FV(
  monthInterest: number,
  countTimeframe: number,
  payments: number
) {
  let value = 0;
  for (let i = 0; i < countTimeframe; i++) {
    value += value * monthInterest;
    value += -1 * payments;
  }

  return value;
}

export function neededCapital({
  wertEntwicklungEntnahmephase: E15,
  inflation: D12,
  currentAge,
  endAge: D7,
  startAge: D6,
  startPension: E3,
  statutoryPension: H8,
  statutoryPensionIncrease,
  pensionScheme: H9,
  hasRealEstate,
}: NeededCapitalArgs) {
  const startPensionWithRealEstate = hasRealEstate ? E3 * 0.8 : E3;

  // TODO: ist die "Gesetzliche Rente Steigerung" jährlich oder monatlich?
  const increasedStatutoryPensionValue =
    statutoryPensionIncrease > 0
      ? zinseszins(H8, statutoryPensionIncrease, D6 - currentAge)
      : H8;

  return PV(
    (1 + E15) ** (1 / 12) - 1,
    300,
    -FV(
      D12 / 12,
      (D7 - D6) * 12,
      -1 * (startPensionWithRealEstate - increasedStatutoryPensionValue - H9)
    ) / 300
  );
}

function zinseszins(startKapital: number, zins: number, laufzeit: number) {
  /*
  Kn = K0 * ((p / 100) + 1)n

Kn	: Endkapital inkl. Zinsen nach n Jahren
K0	: angelegtes Anfangskapital
p	: Zinssatz in Prozent
n	: Anzahl der Jahre
   */
  return startKapital * (zins + 1) ** laufzeit;
}

export type PreparedCapital = Pick<
  OutputValues,
  | "base_pension"
  | "base_pension_monthly"
  | "riester_pension"
  | "riester_pension_monthly"
  | "bav"
  | "bav_monthly"
  | "private_pension"
  | "private_pension_monthly"
  | "depot"
  | "depot_monthly"
  | "performance_savings"
  | "age"
  | "age_entry"
>;

export function preparedCapital({
  base_pension: H14,
  base_pension_monthly: I14,
  riester_pension: H15,
  riester_pension_monthly: I15,
  bav: H16,
  bav_monthly: I16,
  private_pension: H17,
  private_pension_monthly: I17,
  depot: H21,
  depot_monthly: I21,
  performance_savings: E14,
  age_entry: D6,
  age: D5,
}: PreparedCapital) {
  /*
  (SUM(H14+H15+H16+H17+H21))×(1+(E14÷100))^(D6−D5)+(FV((E14%÷12);(D6−D5)×12;−1×(I14+I15+I16+I17+I21+I28)))
   */
  const sumCurrent = H14 + H15 + H16 + H17 + H21;
  const currentMultiplier = (1 + E14) ** (D6 - D5);
  const fv = FV(E14 / 12, (D6 - D5) * 12, -1 * (I14 + I15 + I16 + I17 + I21));
  //console.log(sumCurrent, currentMultiplier, fv);
  return sumCurrent * currentMultiplier + fv;
}

export type AdditionalSavingsRate = Pick<
  OutputValues,
  "performance_savings" | "age" | "age_entry"
> & {
  missingCapital: number;
};

export function additionalSavingsRate({
  performance_savings: E14,
  age: D5,
  age_entry: D6,
  missingCapital: D22,
}: AdditionalSavingsRate) {
  return (D22 * -(E14 / 12)) / (1 - (1 + -(E14 / 12)) ** -((D6 - D5) * 12));
}
