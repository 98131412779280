import clsx from "clsx";
import {
  InputHTMLAttributes,
  LabelHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  FormProvider,
  RegisterOptions,
  useForm,
  useFormContext,
} from "react-hook-form";
import { parsePercentValue, PercentFormatter } from "../../util/formatter";
import { OutputValues } from "../../util/types";
import Accordion from "../accordion/Accordion";
import AccordionItem from "../accordion/AccordionItem";
import styles from "./RechnerForm.module.scss";

export type RechnerFormProps = {
  onChange: (values: OutputValues) => void;
  defaultValues: OutputValues;
};

type FormData = Omit<
  OutputValues,
  | "inflation"
  | "tax_rate"
  | "performance_savings"
  | "performance_withdrawal"
  | "statutory_pension_increase"
> & {
  inflation_percent: string;
  tax_rate_percent: string;
  performance_savings_percent: string;
  performance_withdrawal_percent: string;
  statutory_pension_increase_percent: string;
};

function formatPercentString(value: number) {
  const parts = PercentFormatter.formatToParts(value);
  return parts
    .filter((p) => p.type !== "percentSign")
    .map((p) => p.value)
    .join("")
    .trim();
}

function RechnerForm({
  defaultValues: {
    inflation: dv_inflation,
    tax_rate: dv_tax_rate,
    performance_savings: dv_performance_savings,
    performance_withdrawal: dv_performance_withdrawal,
    statutory_pension_increase: dv_statutory_pension_increase,
    ...dv
  },
  onChange,
}: RechnerFormProps) {
  const triggerOnChangeTimeoutId = useRef<number | null>(null);
  const formDefaultValues: FormData = {
    ...dv,
    inflation_percent: formatPercentString(dv_inflation),
    tax_rate_percent: formatPercentString(dv_tax_rate),
    performance_savings_percent: formatPercentString(dv_performance_savings),
    performance_withdrawal_percent: formatPercentString(
      dv_performance_withdrawal
    ),
    statutory_pension_increase_percent: formatPercentString(
      dv_statutory_pension_increase
    ),
  };

  const formMethods = useForm<FormData>({
    defaultValues: formDefaultValues,
  });
  const { handleSubmit: useFormSubmit, watch } = formMethods;

  const triggerOnChange = useCallback(
    ({
      inflation_percent,
      tax_rate_percent,
      performance_savings_percent,
      performance_withdrawal_percent,
      statutory_pension_increase_percent,
      ...values
    }: FormData) => {
      onChange({
        ...values,
        inflation: parsePercentValue(inflation_percent),
        tax_rate: parsePercentValue(tax_rate_percent),
        performance_savings: parsePercentValue(performance_savings_percent),
        performance_withdrawal: parsePercentValue(
          performance_withdrawal_percent
        ),
        statutory_pension_increase: parsePercentValue(
          statutory_pension_increase_percent
        ),
      });
    },
    [onChange]
  );
  const submitHandle = useFormSubmit(triggerOnChange);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      process.env.NODE_ENV === "development" && console.log(value, name, type);
      if (triggerOnChangeTimeoutId.current) {
        window.clearTimeout(triggerOnChangeTimeoutId.current);
      }
      switch (name) {
        case "use_real_estate":
          triggerOnChange(value as FormData);
          break;
        default:
          triggerOnChangeTimeoutId.current = window.setTimeout(() => {
            triggerOnChange(value as FormData);
          }, 450);
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [triggerOnChange, watch]);

  useEffect(() => {
    if (triggerOnChangeTimeoutId != null) {
    }
  }, [triggerOnChangeTimeoutId]);

  return (
    <FormProvider {...formMethods}>
      <form className={styles.form} onSubmit={submitHandle}>
        <Accordion initActive={["main"]}>
          <AccordionItem
            index="main"
            header="Allgemeine Daten"
            className={styles.page}
          >
            <Input
              inputProps={{
                type: "number",
                min: 1,
                step: 1,
              }}
              label="Alter"
              name="age"
              registerOptions={{
                valueAsNumber: true,
                required: "Bitte geben Sie ihr Alter an.",
              }}
            />
            <Input
              inputProps={{
                type: "number",
                min: 1,
                step: 1,
              }}
              label="Eintrittalter"
              name="age_entry"
              registerOptions={{
                valueAsNumber: true,
                required: "Bitte geben Sie ihr Renten Eintrittsalter an.",
              }}
            />
            <Input
              inputProps={{
                type: "number",
                min: 1,
                step: 1,
                pattern: "\\d*",
              }}
              label="Rentenzahlung bis"
              name="age_till"
              registerOptions={{
                valueAsNumber: true,
                pattern: {
                  message: "Bitte valides Alter eingeben",
                  value: /^[^0][0-9]+/,
                },
                required: "Bis wann beziehen Sie Rente.",
              }}
            />
            <Input
              hint={"Monatliche Netto Rente"}
              inputProps={{
                type: "number",
                min: 1,
                step: 1,
              }}
              label="Wunschrente"
              name="wish_pension"
              inputAdornment={{
                content: "€",
                side: "end",
              }}
              registerOptions={{
                valueAsNumber: true,
                required: "Bitte Wunschrente angeben.",
              }}
            />
          </AccordionItem>
          <AccordionItem
            index={"eck_daten"}
            header="Eckdaten"
            className={styles.page}
          >
            <Input
              label="Inflation"
              inputAdornment={{
                content: "%",
                side: "end",
              }}
              name="inflation_percent"
              registerOptions={{
                required: "Inflation als Prozent Zahl.",
              }}
            />
            <Input
              label="Steuer und Sozialabgaben"
              inputAdornment={{
                content: "%",
                side: "end",
              }}
              name="tax_rate_percent"
              registerOptions={{
                required: "Steuer und Sozialabgaben als Prozent Zahl",
              }}
            />
            <Input
              label="Wertentwicklung Ansparphase"
              inputAdornment={{
                content: "%",
                side: "end",
              }}
              name="performance_savings_percent"
              registerOptions={{
                required: "als Prozent Zahl",
              }}
            />
            <Input
              label="Wertentwicklung Entnahmephase"
              inputAdornment={{
                content: "%",
                side: "end",
              }}
              name="performance_withdrawal_percent"
              registerOptions={{
                required: " als Prozent Zahl",
              }}
            />
          </AccordionItem>
          <AccordionItem
            header="Vorhandene Leistungen"
            index="current_services"
            className={clsx(styles.page, styles.row2)}
          >
            <div className={styles.row2}>
              <Input
                label="Gesetzliche Rente"
                inputAdornment={{
                  content: "€",
                  side: "end",
                }}
                registerOptions={{
                  valueAsNumber: true,
                }}
                name="statutory_pension"
              />
              <Input
                label="Steigerung in %"
                hint="jährliche Steigerung in Prozent."
                inputAdornment={{
                  content: "%",
                  side: "end",
                }}
                name="statutory_pension_increase_percent"
                registerOptions={{
                  required: "Steigerung als Prozent Zahl.",
                }}
              />
            </div>
            <Input
              label="Versorgungswerk"
              inputAdornment={{
                content: "€",
                side: "end",
              }}
              registerOptions={{
                valueAsNumber: true,
              }}
              name="pension_scheme"
            />
            <div className={styles.row2}>
              <Input
                label="Basis Rente"
                inputAdornment={{
                  content: "€",
                  side: "end",
                }}
                registerOptions={{
                  valueAsNumber: true,
                }}
                name="base_pension"
              />
              <Input
                className={styles.row2Input}
                label="monatliche Sparrate"
                inputAdornment={{
                  content: "€",
                  side: "end",
                }}
                registerOptions={{
                  valueAsNumber: true,
                }}
                name="base_pension_monthly"
              />
            </div>
            <div className={styles.row2}>
              <Input
                label="Riester"
                inputAdornment={{
                  content: "€",
                  side: "end",
                }}
                registerOptions={{
                  valueAsNumber: true,
                }}
                name="riester_pension"
              />
              <Input
                className={styles.row2Input}
                label="monatliche Sparrate"
                inputAdornment={{
                  content: "€",
                  side: "end",
                }}
                registerOptions={{
                  valueAsNumber: true,
                }}
                name="riester_pension_monthly"
              />
            </div>
            <div className={styles.row2}>
              <Input
                label="bAV"
                inputAdornment={{
                  content: "€",
                  side: "end",
                }}
                registerOptions={{
                  valueAsNumber: true,
                }}
                name="bav"
              />
              <Input
                className={styles.row2Input}
                label="monatliche Sparrate"
                inputAdornment={{
                  content: "€",
                  side: "end",
                }}
                registerOptions={{
                  valueAsNumber: true,
                }}
                name="bav_monthly"
              />
            </div>
            <div className={styles.row2}>
              <Input
                label="Private Rentenversicherung"
                inputAdornment={{
                  content: "€",
                  side: "end",
                }}
                registerOptions={{
                  valueAsNumber: true,
                }}
                name="private_pension"
              />
              <Input
                className={styles.row2Input}
                label="monatliche Sparrate"
                inputAdornment={{
                  content: "€",
                  side: "end",
                }}
                registerOptions={{
                  valueAsNumber: true,
                }}
                name="private_pension_monthly"
              />
            </div>
          </AccordionItem>
          <AccordionItem
            index="other_investments"
            header="Andere Anlagen"
            className={styles.page}
          >
            <div className={styles.row2}>
              <Input
                label="Depot"
                inputAdornment={{
                  content: "€",
                  side: "end",
                }}
                registerOptions={{
                  valueAsNumber: true,
                }}
                name="depot"
              />
              <Input
                label="monatliche Sparrate"
                inputAdornment={{
                  content: "€",
                  side: "end",
                }}
                registerOptions={{
                  valueAsNumber: true,
                }}
                name="depot_monthly"
              />
            </div>
            <Input
              label="Immobilie"
              inputProps={{
                type: "checkbox",
              }}
              name="use_real_estate"
            />
          </AccordionItem>
        </Accordion>
      </form>
    </FormProvider>
  );
}

type InputProps = {
  label: string;
  name: keyof FormData;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  registerOptions?: RegisterOptions<FormData>;
  inputAdornment?: {
    side?: "start" | "end";
    content: string;
  };
  hint?: string | JSX.Element;
} & Pick<LabelHTMLAttributes<HTMLLabelElement>, "className">;

function Input({
  className,
  hint,
  label,
  inputAdornment,
  inputProps,
  name,
  registerOptions,
}: InputProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext<FormData>();
  const [showHint, setShowHint] = useState(false);

  return (
    <label className={className}>
      <div className={styles.labelContainer}>
        <div className={styles.label}>{label}</div>
        {hint && (
          <div
            className={styles.hintContainer}
            onMouseEnter={() => {
              setShowHint(true);
            }}
            onMouseLeave={() => {
              setShowHint(false);
            }}
          >
            i
            <div className={clsx(styles.hint, showHint && styles.show)}>
              {hint}
            </div>
          </div>
        )}
      </div>
      <div
        className={clsx(
          styles.inputContainer,
          inputProps && inputProps.type === "checkbox" && styles.inputCheckbox,
          inputAdornment && styles.inputAdornmentContainer,
          inputAdornment && inputAdornment.side === "start"
            ? styles.inputAdornmentStart
            : styles.inputAdornmentEnd
        )}
      >
        <input {...inputProps} {...register(name, registerOptions)} />
        {inputAdornment && (
          <span className={clsx(styles.inputAdornment)}>
            {inputAdornment.content}
          </span>
        )}
      </div>
      <span>{errors[name]?.message}</span>
    </label>
  );
}

export default RechnerForm;
