import { DependencyList, useEffect, useRef, useState } from "react";

export function useEffectTimeout(
  cb: () => void,
  timeoutDur: number,
  deps: DependencyList
) {
  const refTimeout = useRef<number | null>(null);
  useEffect(() => {
    if (refTimeout.current != null) {
      window.clearTimeout(refTimeout.current);
    }
    refTimeout.current = window.setTimeout(() => {
      cb();
    }, timeoutDur);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeoutDur, cb, ...deps]);
}

export function useMediaQuery(mediaQuery: string) {
  const [isMatch, setIsMatch] = useState(false);
  const [mediaQueryList, setMediaQueryList] = useState<MediaQueryList | null>(
    null
  );

  useEffect(() => {
    const list = window.matchMedia(mediaQuery);
    setMediaQueryList(list);
    setIsMatch(list.matches);
  }, [mediaQuery]);

  useEventListener("change", (e: any) => setIsMatch(e.matches), mediaQueryList);

  return isMatch;
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.document.documentElement.clientWidth,
    height: window.innerHeight,
  });

  useEventListener("resize", () => {
    setWindowSize({
      width: window.document.documentElement.clientWidth,
      height: window.innerHeight,
    });
  });

  useEventListener(
    "change",
    () => {
      setWindowSize({
        width: window.document.documentElement.clientWidth,
        height: window.innerHeight,
      });
    },
    window.screen.orientation
  );

  return windowSize;
}

export function useEventListener(
  eventType: any,
  callback: EventListener,
  element: any | null = window
) {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (element == null) return;
    const handler = (e: Event) => callbackRef.current(e);
    element.addEventListener(eventType, handler);

    return () => element.removeEventListener(eventType, handler);
  }, [eventType, element]);
}
