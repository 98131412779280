import type { PlotData } from "plotly.js";
// @ts-ignore
import * as PlotlyLib from "plotly.js-basic-dist-min";
import { useMemo, useState } from "react";
import createPlotlyComponent from "react-plotly.js/factory";
import { CurrencyFormatter } from "../../util/formatter";
import { useMediaQuery, useWindowSize } from "../../util/hooks";
import styles from "./Plotly.module.scss";

const Plot = createPlotlyComponent(PlotlyLib);

export type PlotlyProps = {
  additionalSavingsRate: number;
  missingCapital: number;
  neededCapital: number;
  preparedCapital: number;
};

function Plotly({
  missingCapital,
  neededCapital,
  preparedCapital,
}: PlotlyProps) {
  const [isStackMode, setIsStackMode] = useState(false);
  const legendBelow = useMediaQuery("(min-width: 768px)");
  const { width } = useWindowSize();

  const chartWidth = useMemo(() => {
    let chartWidth = 600;
    if (!legendBelow) {
      chartWidth = Math.min(chartWidth, width - 100);
    }
    return chartWidth;
  }, [legendBelow, width]);

  const splitData: Partial<PlotData>[] = [
    {
      type: "bar",
      yaxis: "in €",
      x: [1],
      y: [neededCapital],
      text: [CurrencyFormatter.format(neededCapital)],
      textfont: {
        color: "rgb(255,255,255)",
        family: `poppinslight, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif`,
      },
      name: "Erforderliches<br>Altersvermögen",
      legendgroup: "needed_capital",
      showlegend: true,
      marker: {
        color: ["rgb(0, 110, 183)"],
      },
    },
    {
      type: "bar",
      yaxis: "in €",
      x: [2],
      y: [preparedCapital],
      text: [CurrencyFormatter.format(preparedCapital)],
      textfont: {
        color: "rgb(255,255,255)",
        family: `poppinslight, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif`,
      },
      name: "Vorbereitetes<br>Altersvermögen",
      marker: {
        color: ["rgb(135,178,75)"],
      },
    },
    {
      type: "bar",
      yaxis: "in €",
      text: [CurrencyFormatter.format(missingCapital)],
      textfont: {
        color: "rgb(255,255,255)",
        family: `poppinslight, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif`,
      },
      x: [2],
      name: "Lücke<br>Altersvermögen",
      legendgroup: "missing_capital",
      y: [missingCapital],
      marker: {
        color: [missingCapital < 0 ? "rgb(240,126,38)" : "rgb(135,178,75)"],
      },
    },
  ];

  const stackedData: Partial<PlotData>[] = [
    {
      type: "bar",
      yaxis: "in €",
      x: [1],
      y: [preparedCapital],
      text: [CurrencyFormatter.format(preparedCapital)],
      textfont: {
        color: "rgb(255,255,255)",
        family: `poppinslight, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif`,
      },
      name: "Vorbereitetes<br>Altersvermögen",
      marker: {
        color: ["rgb(135,178,75)"],
      },
    },
    {
      type: "bar",
      yaxis: "in €",
      text: [
        CurrencyFormatter.format(
          missingCapital < 0 ? -1 * missingCapital : missingCapital
        ),
      ],
      textfont: {
        color: "rgb(255,255,255)",
        family: `poppinslight, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif`,
      },
      x: [1],
      name: "Lücke<br>Altersvermögen",
      legendgroup: "missing_capital",
      y: [missingCapital < 0 ? -1 * missingCapital : missingCapital],
      marker: {
        color: [missingCapital < 0 ? "rgb(240,126,38)" : "rgb(135,178,75)"],
      },
    },
  ];

  return (
    <>
      <label className={styles.changeChartMode}>
        <input
          type="checkbox"
          checked={isStackMode}
          onChange={(e) => setIsStackMode(e.target.checked)}
        />
        Chart mode wechseln
      </label>
      <Plot
        className={styles.chart}
        data={isStackMode ? stackedData : splitData}
        config={{
          displayModeBar: false,
          showAxisDragHandles: false,
          showAxisRangeEntryBoxes: false,
        }}
        layout={{
          paper_bgcolor: "transparent",
          plot_bgcolor: "transparent",
          autosize: true,
          width: chartWidth,
          xaxis: {
            visible: false,
          },
          legend: {
            orientation: "h",
          },
          yaxis: {
            ticksuffix: " €",
            showline: true,
            showgrid: true,
            separatethousands: true,
            gridcolor: "rgba(0,0,0,0.13)",
          },
          font: {
            family: `poppinslight, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif`,
          },
          margin: { t: 0, b: 0, r: 15, l: 45, pad: 0 },
          title: "",
          hovermode: false,
          dragmode: false,
          barmode: isStackMode ? "stack" : "overlay",
          showlegend: true,
        }}
      />
    </>
  );
}

export default Plotly;
