import clsx from "clsx";
import { HTMLAttributes, PropsWithChildren, useContext, useRef } from "react";
import { useEffectTimeout } from "../../util/hooks";
import { AccordionContext } from "./Accordion";
import styles from "./AccordionItem.module.scss";

type AccordionItemProps = PropsWithChildren<
  { header: string; index: string } & Pick<
    HTMLAttributes<HTMLDivElement>,
    "className"
  >
>;

function AccordionItem({
  children,
  className,
  header,
  index,
}: AccordionItemProps) {
  const accordionContent = useRef<HTMLDivElement>(null);
  const { state, toggleActive } = useContext(AccordionContext);

  const isOpen = state[index] ?? false;

  useEffectTimeout(
    () => {
      if (isOpen && accordionContent.current) {
        accordionContent.current.style.height = "auto";
        accordionContent.current.classList.add(styles.hasHint);
      }
    },
    200,
    [isOpen, accordionContent.current]
  );
  return (
    <div className={styles.accordionItem}>
      <header
        onClick={() => toggleActive(index)}
        className={clsx(styles.header, isOpen && styles.open)}
      >
        {header}
        {isOpen ? (
          <span className={styles.icon}>{"-"}</span>
        ) : (
          <span className={styles.icon}>{"+"}</span>
        )}
      </header>
      <div
        ref={accordionContent}
        className={clsx(
          className,
          styles.accordionContent,
          isOpen && styles.open
        )}
        style={
          isOpen
            ? { height: accordionContent.current?.scrollHeight }
            : { height: "0px" }
        }
      >
        {children}
      </div>
    </div>
  );
}

export default AccordionItem;
