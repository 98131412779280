import { createContext, PropsWithChildren, useCallback, useState } from "react";

export type AccordionContextValue = {
  toggleActive: (index: string) => void;
  isActive: (index: string) => boolean;
  state: { [index: string]: boolean };
};

export const AccordionContext = createContext<AccordionContextValue>({
  toggleActive: () => {},
  isActive: () => false,
  state: {},
});
if (process.env.NODE_ENV === "development") {
  AccordionContext.displayName = "AccordionContext";
}

export type AccordionProps = {
  initActive?: string[];
};

function Accordion({
  children,
  initActive,
}: PropsWithChildren<AccordionProps>) {
  const [active, setActive] = useState<{ [index: string]: boolean }>(() => {
    const initState: { [index: string]: boolean } = {};
    if (initActive != null) {
      initActive.forEach((index) => {
        initState[index] = true;
      });
    }
    return initState;
  });

  const toggleActive = useCallback((index: string) => {
    setActive((a) => {
      const _t = { ...a };
      if (!(index in _t)) {
        _t[index] = true;
      } else {
        _t[index] = !_t[index];
      }
      return _t;
    });
  }, []);

  const isActive = useCallback(
    (index: string) =>
      index in active && typeof active[index] === "boolean" && active[index],
    [active]
  );

  return (
    <AccordionContext.Provider
      value={{ state: active, isActive, toggleActive }}
    >
      {children}
    </AccordionContext.Provider>
  );
}

export default Accordion;
