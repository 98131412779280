export const PercentFormatter = new Intl.NumberFormat("de", {
  style: "percent",
  minimumFractionDigits: 2,
});

export function parsePercentValue(raw: string): number {
  if (!raw || raw === "") return 0;
  let percent: number = parseFloat(raw.replace(".", "").replace(",", "."));
  if (isNaN(percent)) percent = 0;
  else percent = percent / 100;
  return percent;
}

export const CurrencyFormatter = new Intl.NumberFormat("de", {
  style: "currency",
  currency: "EUR",
});

export function Currency({ value }: { value: number }) {
  return <>{CurrencyFormatter.format(value)}</>;
}
