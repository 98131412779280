import { useEffect, useState } from "react";
import styles from "./App.module.scss";
import finumLogo from "./assets/FinumLogo.svg";
import RechnerForm from "./components/form/RechnerForm";
import LocalComponents from "./components/local/LocalComponents";
import OutputTest from "./components/OutputTest";
import DataOverview from "./components/overview/DataOverview";
import GlobalWrapper from "./util/GlobalWrapper";
import { OutputValues } from "./util/types";

const initialState: OutputValues = {
  age: 27,
  age_till: 92,
  age_entry: 67,
  wish_pension: 2000,
  inflation: 0.015,
  tax_rate: 0.2,
  performance_savings: 0.05,
  performance_withdrawal: 0.03,
  statutory_pension: 0,
  statutory_pension_increase: 0,
  pension_scheme: 0,
  base_pension: 0,
  base_pension_monthly: 0,
  riester_pension: 0,
  riester_pension_monthly: 0,
  bav: 0,
  bav_monthly: 0,
  private_pension: 0,
  private_pension_monthly: 0,
  depot: 0,
  depot_monthly: 0,
  use_real_estate: false,
};

function App() {
  const [mode, setMode] = useState<"inline" | "standalone">("inline");
  const [showContactForm, setShowContactForm] = useState(false);
  useEffect(() => {
    const rootEl = document.getElementById(
      process.env.REACT_APP_ROOT_ELEMENT_ID || "root"
    );
    if (rootEl) {
      if (rootEl.hasAttribute("data-standalone")) {
        setMode("standalone");
      }
      if (rootEl.hasAttribute("data-contact-form")) {
        setShowContactForm(true);
      }
    }
  }, []);

  const [outputValues, setOutputValues] = useState<OutputValues | undefined>(
    initialState
  );

  const handleLocalComponentsChange = (
    type: string,
    value: string | boolean
  ) => {
    switch (type) {
      case "showContactForm":
        setShowContactForm(value as boolean);
    }
  };

  return (
    <GlobalWrapper>
      <div className={styles.root}>
        <header className={styles.header}>
          {mode === "standalone" && <img src={finumLogo} alt="Finum Logo" />}
        </header>
        <div className={styles.form}>
          <RechnerForm
            onChange={setOutputValues}
            defaultValues={initialState}
          />
          <DataOverview values={outputValues} />
        </div>

        {showContactForm && (
          <div>
            <form>
              <h2>Kontakt Formular</h2>
            </form>
          </div>
        )}
      </div>
      <div className={styles.version}>
        v{process.env.REACT_APP_VERSION} - {mode}
      </div>
      {process.env.NODE_ENV === "development" && (
        <>
          <LocalComponents
            showContactForm={showContactForm}
            onChange={handleLocalComponentsChange}
          />
          <OutputTest values={outputValues} />
        </>
      )}
    </GlobalWrapper>
  );
}

export default App;
