import clsx from "clsx";
import { useMemo } from "react";
import {
  additionalSavingsRate,
  neededCapital,
  preparedCapital,
  wishPensionWithAge,
} from "../../util/Calculation";
import { Currency } from "../../util/formatter";
import { OutputValues } from "../../util/types";
import Plotly from "../plotly/Plotly";
import styles from "./DataOverview.module.scss";

export type DataOverviewProps = {
  values?: OutputValues;
};

function DataOverview({ values }: DataOverviewProps) {
  const pensionStartValue = useMemo(() => {
    if (!values) return -1;
    return wishPensionWithAge({
      age: values.age_entry,
      taxRate: values.tax_rate,
      currentAge: values.age,
      inflation: values.inflation,
      currentWishPension: values.wish_pension,
    });
  }, [values]);
  const pensionEndValue = useMemo(() => {
    if (!values) return -1;
    return wishPensionWithAge({
      age: values.age_till,
      taxRate: values.tax_rate,
      currentAge: values.age,
      inflation: values.inflation,
      currentWishPension: values.wish_pension,
    });
  }, [values]);

  const neededCapitalValue = useMemo(() => {
    if (!values) return -1;
    return neededCapital({
      pensionScheme: values.pension_scheme,
      inflation: values.inflation,
      statutoryPension: values.statutory_pension,
      statutoryPensionIncrease: values.statutory_pension_increase,
      startPension: pensionStartValue,
      currentAge: values.age,
      startAge: values.age_entry,
      endAge: values.age_till,
      wertEntwicklungEntnahmephase: values.performance_withdrawal,
      hasRealEstate: values.use_real_estate,
    });
  }, [pensionStartValue, values]);

  const preparedCapitalValue = useMemo(() => {
    if (!values) return -1;
    return preparedCapital({
      age: values.age,
      age_entry: values.age_entry,
      base_pension: values.base_pension,
      base_pension_monthly: values.base_pension_monthly,
      bav: values.bav,
      bav_monthly: values.bav_monthly,
      depot: values.depot,
      depot_monthly: values.depot_monthly,
      performance_savings: values.performance_savings,
      private_pension: values.private_pension,
      private_pension_monthly: values.private_pension_monthly,
      riester_pension: values.riester_pension,
      riester_pension_monthly: values.riester_pension_monthly,
    });
  }, [values]);

  const missingCapitalValue = useMemo(() => {
    if (!values) return -1;
    return preparedCapitalValue - neededCapitalValue;
  }, [neededCapitalValue, preparedCapitalValue, values]);

  const additionalSavingsRateValue = useMemo(() => {
    if (!values) return -1;
    return additionalSavingsRate({
      performance_savings: values.performance_savings,
      age: values.age,
      age_entry: values.age_entry,
      missingCapital: missingCapitalValue,
    });
  }, [missingCapitalValue, values]);

  return (
    <>
      <div>
        <div className={styles.container}>
          <header className={styles.header}>Übersicht</header>
          <div className={styles.grid}>
            <div className={styles.gridLabel}>Wunschrente</div>
            <div className={styles.gridValue}>
              {<Currency value={values?.wish_pension ?? 0} />}
            </div>
            <div className={styles.gridLabel}>
              Rente ab {values?.age_entry ?? ""}
            </div>
            <div className={styles.gridValue}>
              {pensionStartValue >= 0 && <Currency value={pensionStartValue} />}
            </div>
            <div className={styles.gridLabel}>
              Rente bis {values?.age_till ?? ""}
            </div>
            <div className={styles.gridValue}>
              {pensionEndValue >= 0 && <Currency value={pensionEndValue} />}
            </div>
            <div className={styles.gridLabel}>
              Erforderliches Altersvermögen
            </div>
            <div className={styles.gridValue}>
              {neededCapitalValue >= 0 && (
                <Currency value={neededCapitalValue} />
              )}
            </div>
            <div className={styles.gridLabel}>Vorbereitetes Altersvermögen</div>
            <div className={styles.gridValue}>
              {preparedCapitalValue >= 0 && (
                <Currency value={preparedCapitalValue} />
              )}
            </div>
            <div className={styles.gridLabel}>Lücke Altersvorsorgevermögen</div>
            <div
              className={clsx(
                styles.gridValue,
                missingCapitalValue < 0
                  ? styles.negativeCapital
                  : styles.positiveCapital
              )}
            >
              <Currency value={missingCapitalValue} />
            </div>
            <div className={styles.gridLabel}>zusätzliche Sparrate</div>
            <div
              className={clsx(
                styles.gridValue,
                missingCapitalValue < 0
                  ? styles.negativeCapital
                  : styles.positiveCapital
              )}
            >
              <Currency value={additionalSavingsRateValue} />
            </div>
          </div>
        </div>
        <div className={styles.chart}>
          <Plotly
            additionalSavingsRate={additionalSavingsRateValue}
            missingCapital={missingCapitalValue}
            neededCapital={neededCapitalValue}
            preparedCapital={preparedCapitalValue}
          />
        </div>
      </div>
    </>
  );
}

export default DataOverview;
